/**
 * NOTE: There's a big ol' stanky bug with styled-components where dynamically
 * updating styles based on prop changes rerenders the entire stylesheet,
 * including font-face declarations.
 *
 * Render this as separate CSS so it will be added separately from the
 * styled-components tag.
 *
 * https://github.com/styled-components/styled-components/issues/400
 */
@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/Montserrat-Thin.otf');
  font-weight: 100;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/Montserrat-ThinItalic.otf');
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/Montserrat-Light.otf');
  font-weight: 300;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/Montserrat-LightItalic.otf');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/Montserrat-Regular.otf');
  font-weight: 400;
}

/* @font-face {
  font-family: 'Montserrat';
  src: url('../fonts/Montserrat-RegularItalic.otf');
  font-weight: 400;
  font-style: italic;
} */

@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/Montserrat-Medium.otf');
  font-weight: 500;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/Montserrat-MediumItalic.otf');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/Montserrat-SemiBold.otf');
  font-weight: 700;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/Montserrat-SemiBoldItalic.otf');
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/Montserrat-Black.otf');
  font-weight: 900;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/Montserrat-BlackItalic.otf');
  font-weight: 900;
  font-style: italic;
}
